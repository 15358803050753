
.preloader
	position fixed
	top 0
	left 0
	background #fff
	z-index 1999
	width 100%
	height 100%
	overflow hidden
	font-family sans-serif
	&.hide {
		opacity 0
		transition .3s
		transition-delay .5s
		pointer-events none
	}	
	&.remove {
		display none
	}
	.loader 
		width 72px
		height 50px
		position absolute
		top 0 
		right 35px 
		left 0 
		bottom 0
		margin auto
		&__img 
			width 100px
			height 110px
			font-size 40px
			text-align center
			transform-origin bottom center
			animation 3s rotate infinite
			opacity 0
			position absolute
			bottom 15px
		span 
			display block
			width 100%
			text-align center
			position absolute
			bottom 0
			font-size 35px
			color #581e2f
		svg
			fill #581e2f

@keyframes rotate
	0% 
		transform rotate(180deg)
	5% 
		opacity 0
	35% 
		transform rotate(0deg)
		opacity 1
	65% 
		transform rotate(0deg)
		opacity 1
	85% 
		opacity 0
	100% 
		transform rotate(-180deg)
  


.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: fixed;
	top 0;
	left 0;
	background: transparent;
	z-index: 2000;
	width: 100%;
	height: 100%;
	overflow: hidden;
	font-family sans-serif;
}

.pace-done .pace{
	display none
}
.pace.pace-inactive .pace-progress {
  display: none;
}

.pace .pace-progress {
  position: fixed;
  z-index: 2001;
  height: 2.3rem;
  width: 5rem;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0) !important;
  -ms-transform: translate3d(-50%, -50%, 0) !important;
  transform: translate3d(-50%, -50%, 0) !important;
}

.pace .pace-progress:after {
  display: block;
  position: absolute;

  content: attr(data-progress-text);
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 100;
  line-height: 1;
  text-align: right;
  font-size: 35px;
	color: #581e2f;
}

// .pace-progress-inner {
// 	position: relative;
// }
